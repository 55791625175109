// add more css stuff here
html {
  scroll-behavior: smooth;
}

body {
    color: #333333;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
}

body article.post {
  font-size: 16px;
}
body .post-preview {
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6 {
    color: #000;
    line-height: 1;
    font-weight: 700;
}

h1 {
    font-size: 36px;
}

blockquote {
    font-style: italic;
    font-size: larger;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.lead {
    font-size: 18px;
}

.page-title {
    font-size: 24px;
}

.wistia_embed {
	box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.15);
}
.w-80 {
  width: 80px;
}
.heading-hero {
  font-size: 32px;
  font-weight: 700;
}
.post-preview a:hover {
  text-decoration: none;
}
.bold {
	font-weight: bold;
}
.fw-300 {
  font-weight: 300;
}
.fw-500 {
  font-weight: 500;
}

.border-right {
	border-right:solid 1px #eee;
}
.btn-inverse {
  border: 1px solid $brand-primary;
  padding: 5px 10px 5px 10px;
}
a:hover, a:focus {
	color: $brand-primary;
}

.panel-body {
  padding: 20px;
}
h5.media-heading {
	font-size: 16px;
}

.blog-content-img {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* -----------------------------------------------
 * Breadcrumb Styles
 * --------------------------------------------- */

.breadcrumb-wrapper {
    background-color: #f9f9f9;
    margin-bottom: 23px;
    .breadcrumb {
        margin-bottom: 0;
        background-color: #f9f9f9;
    }
}
/* -----------------------------------------------
 * Section Background Styles
 * --------------------------------------------- */
 .bg-white {
    background-color: #fff;
 }

.bg-primary-color {
  background-color: $brand-primary;
  color: #ffffff;
}
.bg-primary-color h1, .bg-primary-color h2, .bg-primary-color h3, .bg-primary-color h4, .bg-primary-color h5 , .bg-primary-color h6 {
  color: #ffffff;
}
.bg-primary-color a, .bg-primary-color a:hover{
  color: #ffffff;
  text-decoration: none;
}

.bg-success-color {
  background: $brand-success;
  color: #ffffff !important;
}
.bg-success-color h1, .bg-success-color h2, .bg-success-color h3, .bg-success-color h4 {
  color: #ffffff !important;
}
.bg-success-color a, .bg-success-color a:hover{
  color: #ffffff !important;
  text-decoration: none;
}

.bg-light-grey {
	background-color: #eee;
}

.bg-dark-grey {
  background-color: #2f3035;
}
.bg-dark-grey h1, .bg-dark-grey h2, .bg-dark-grey h3, .bg-dark-grey h4, .bg-dark-grey h5, .bg-dark-grey h6, .bg-dark-grey p {
  color: #ffffff;
}


ul.lead {
  font-size: 18px;
  line-height: 40px;
}
ul.lead i {
    margin-right: 10px;
}
/* -----------------------------------------------
 * Blog Posts
 * --------------------------------------------- */
.blog-index-image {
    object-fit: cover;
    width: 100%;
    height: 420px;
}
.blog-post-featured-image {
    object-fit: cover;
    width: 100%;
    height: 470px;
}
@media (min-width: 775px) {
    .blog-post-affix.affix {
        position: fixed;
        top: 10px;
    }
}

 /* -----------------------------------------------
 * How it Works
 * --------------------------------------------- */
 .how-it-works .help-text {
  width: 195px;
  font-size: 14px;
  color: #676a73;
  margin: 40px 0 0 0;

 }
/* -----------------------------------------------
 * Button Styles
 * --------------------------------------------- */
.btn {
    text-transform: none;
    font-weight: 700;
    box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.15);
}
.btn-link {
  box-shadow: none;
}
.btn-default {
  color: #000;
}

.bg-primary-color .btn-inverse {
  background-color: none;
  border-color: #fff;
  padding: 5px 10px 4px 10px;
  border: 1px solid;
}

.btn-xl {
  padding: 15px 30px;
  font-size: 19px;
  line-height: normal;
}

.request-button {
  margin-top: -500px;
}
/* -----------------------------------------------
 * Navbar Styles
 * --------------------------------------------- */
.navbar {
  margin-bottom: 0px;
  border-radius: 0px;
  box-shadow: 0 1px 2px 0 rgba(136, 144, 195, 0.2);
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}

.navbar-nav {
  margin: 0 0 0 10px
}

.navbar-nav > li.active > a {
  background: none repeat scroll 0 0 rgba(0,0,0,0);
    box-shadow: 0 -3px 0 0 $brand-primary inset;
}

.navbar-nav > li > a:hover {
  background: none repeat scroll 0 0 rgba(0,0,0,0);
    box-shadow: 0 -3px 0 0 $brand-primary inset;
}

.navbar-nav > li > a {
  color: $headings-color;
  font-weight: 700;
}

.navbar-brand {
  color: $headings-color;
}

.navbar-toggle {
  .icon-bar {
     background-color: $text-color;
  }
}

.navbar-brand {
  display: block;
  padding: 16px 10px;

}

.navbar-brand > img {
  height: 32px;
}

.navbar-brand > p > span {
  color: $brand-primary;
}

.img-profile-avatar {
  margin-top: -5px;
}

.navbar.sub-nav {
  min-height: 32px;
  border-top: 1px solid #eee;
}
.sub-nav .navbar-nav > li > a{
  padding-top:5px;
  padding-bottom:5px;
}

.border-bottom-grey {
  border-bottom: 1px solid #ddd;
}

.navbar.no-border {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* -----------------------------------------------
 * Hero Styles
 * --------------------------------------------- */
.hero {
    .heading {
        font-size: 50px;
    }
    nav {
        background: rgba(0, 156, 255, 0.53);
        border-bottom: 1px solid rgba(16, 125, 213, 0.77);
    }
    .navbar-nav>li>a:hover {
        background: none repeat scroll 0 0 transparent;
        box-shadow: 0 -1px 0 0 #fff inset;
    }
    .navbar-nav > li > a {
        text-transform: uppercase;
        color: #fff;
    }
}
.hero-screenshot {
  width: 80%;
}

/* -----------------------------------------------
 * Section Styles
 * --------------------------------------------- */
.section-image {
  max-height: 400px;
}

.img-whitepaper-preview {
  max-height: 375px;
  margin-right: -4px;
  margin-top: -75px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.15);
}

/* -----------------------------------------------
 * Footer Styles
 * --------------------------------------------- */
footer {
    background-color: #f9f9f9;

    h5 {
        font-size: 16px;
        text-transform: uppercase;
    }
    .footer-link-list a {
        color: #7b7b7b;
    }

    .footer-features-2-column {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
}



.license-terms {
  font-size: 14px;
  font-weight: 300;
}
/* -----------------------------------------------
 * Pricing Styles
 * --------------------------------------------- */

.price {
    border-radius: 5px;
    margin:10px;
    color: #000;

  .dollar {
        font-size: 45px;
        line-height: 1em;
        position: absolute;
        top: 30px;
    }
    .cent {
        font-size: 45px;
        line-height: 1em;
    }
    .figure {
        font-size: 96px;
        padding: 0 0 0 14px;
        font-weight: 500;
        line-height: 140px;
    }
    .month {
        font-size: 16px;
    }
}

.price-interval {
  margin-top: -10px;
}
.price-plus {
  font-size: 36px;
  line-height: 1em;
  position: absolute;
  top: 86px;
  right: -18px;
}

// Form Styles

/* --- material floating label --- */

.floating-label.form-group {
    display: flex;
    height: 55px;
}

.floating-label .control-label {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 22px, 0) scale(1);
    transform-origin: left top;
    transition: 240ms;
}

.floating-label.form-group.focused .control-label {
    opacity: 1;
    transform: scale(0.75);
}

.floating-label .form-control {
    align-self: flex-end;
}

.floating-label .form-control::-webkit-input-placeholder {
    color: transparent;
    transition: 240ms;
}

.floating-label .form-control:focus::-webkit-input-placeholder {
    transition: none;
}

.floating-label .form-group.focused .form-control::-webkit-input-placeholder {
    color: #bbb;
}

.floating-label.form-group.textarea-group {
  height: auto;
  min-height:110px;
}

.segment-hero {
  background: #1f2c39;
  min-height: 600px;
  background-size: cover;
  color: #ffffff !important;
}
.segment-hero h2 {
  color: #fff;
  font-weight: 300;
}

.segment-primary {
  background: #1D93F0;
}
.segment-primary p.lead, .segment-primary h3 {
  color: #ffffff !important;
}

// hompage
.hero-img-mockup {
  height: 500px;
  overflow: hidden;
}
.hero-img-mockup img {
  max-width: 740px;
  opacity: .35;
}
.hero-img-mockup img:hover {
  opacity: .9;
  transition: opacity .9s ease-in-out;
    -moz-transition: opacity .9s ease-in-out;
    -webkit-transition: opacity .9s ease-in-out;
    -o-transition: opacity .9s ease-in-out;
}

//slidedown header
.slide-down-header.hidden {
  top: -150px;
}
.slide-down-header.show {
  top: 0px;
}
.slide-down-header {
  -webkit-transition: top 0.9s ease-in-out;
  -moz-transition: top 0.9s ease-in-out;
  -o-transition: top 0.9s ease-in-out;
  -ms-transition: top 0.9s ease-in-out;
  transition: top 0.9s ease-in;
}


/* -----------------------------------------------
 * Material Design Icons
 * --------------------------------------------- */
.md-6x {
  font-size: 6em;
}

// Rules for sizing the icon.
.material-icons {
  vertical-align: middle;

    &.md-16 {
      font-size: 16px;
    }
    &.md-18 {
      font-size: 18px;
    }
    &.md-24 {
      font-size: 24px;
    }
    &.md-28 {
      font-size: 28px;
    }
    &.md-32 {
      font-size: 32px;
    }
    &.md-36 {
      font-size: 36px;
    }
    &.md-48 {
      font-size: 48px;
    }
}

// Rules for using icons as black on a light background.
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

// Rules for using icons as white on a dark background.
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.rating-star {
    color: #FDD835;
}
/* -----------------------------------------------
 * Definition list styles
 * --------------------------------------------- */
.post-title {
    font-size: 34px;
}
 .post {
    counter-reset: post-counter;
    font-size: 16px;
}
.post dt {
    position: relative;
    padding: 8px 0 10px 0;
    color: #444;
    font: inherit;
    font-size: 16px;
    font-weight: bold;
}
.post dt:before {
    content: counter(post-counter);
    counter-increment: post-counter;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 16px;
    background: $brand-primary;
    padding: 0px 10px;
    color: #fff;
    border-radius: 100px;
    font-weight: 400;
    margin-top: 8px;
}
.post dd {
    margin: 0 0 20px 0;
}
.post dt, .post dd {
    padding-left: 45px;
}


/* -----------------------------------------------
 * Utilities
 * --------------------------------------------- */
.grey-scale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

/* -----------------------------------------------
 * Image Clip
 * --------------------------------------------- */
@media (min-width: 768px) {
    .img-clip {
        object-fit: cover;
        width: 100%;
        height: auto;
        object-position: 100% 0;
        &.h-260 {
            height: 260px;
        }
        &.h-280 {
            height: 280px;
        }
        &.h-300 {
            height: 300px;
        }
        &.h-360 {
            height: 360px;
        }
        &.h-500 {
            height: 500px;
        }
    }
}
/* -----------------------------------------------
 * Highligher
 * --------------------------------------------- */
 mark {
    background:#acffaf;
}

/* -----------------------------------------------
 * Trust Badges
 * --------------------------------------------- */
.trust-badges {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
}
