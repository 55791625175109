/* -----------------------------------------------
 * Feature styles
 * --------------------------------------------- */

.feature-list-material-icons {
    direction: ltr;
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 1.7em;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

 .features-list {
    padding-left: 0;
    list-style: none;

    li {
        margin: 15px 0px 15px 35px;

        &:before {
            @extend .feature-list-material-icons;
            content: 'check';
            color: $brand-success;
            vertical-align: top;
            position: absolute;
            left: 13px;
        }
    }
}

 .features-item {
    margin: 0 0 20px 0;
    max-height: 280px;
    a, a:hover {
       color: $text-color;
        text-decoration: none;
    }
    .features-icon {
        margin: 20px 0;
        padding: 40px;
        background-color: $well-bg;
        text-align: center;
        color: $brand-primary;
        border-radius: 3px;
        box-shadow: 0 1px 2px 0 rgba(136,144,195,0.2), 0 2px 5px 0 rgba(37,44,97,0.15);
        .material-icons {
            font-size: 60px;
        }
    }
}

.features-details {
    h5 {
        margin-top: 25px;
    }
    .features-icon {
        margin: 40px 0;
        padding: 60px;
        background-color: $well-bg;
        text-align: center;
        color: $brand-primary;
        box-shadow: 0 1px 2px 0 rgba(136,144,195,0.2), 0 2px 5px 0 rgba(37,44,97,0.15);
        .material-icons {
            font-size: 100px;
        }
    }
    .feature-title {
        font-size: 34px;
    }
}
