// pricing page styles

.package-name {
    text-align: center;
    font-weight: 400;
}

.package-description {
    text-align: center;
    padding: 0 18px;
    .highlights {
        font-size: 16px;
        color: #000;
    }
}

.package-price-tag {
     text-align: center;

    .price {
        display: block;
        margin: 0;
        .sign{
            display: inline-block;
            text-align: center;
            font-size: 28px;
            font-weight: 400;
            vertical-align: middle;
        }
        .currency {
            text-align: center;
            font-size: 60px;
            font-weight: bold;
            line-height: 60px;
            padding: 0;
            vertical-align: middle;
        }
    }
    .month{
        text-align: center;
        display: block;
        font-size: 14px;
        color:#7b7b7b;
    }
}

.package.list-group {
    border: none;
    border-radius: 2px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);

    &.active {
        border: 2px solid $brand-primary;
        border-radius: 3px;
        margin-top: -15px;
    }

    .list-group-item {
        border: none;
        padding: 10px;
    }
}


.table.pricing {
    color: #000;
    th {
        text-align: center;
        background: #f5f5f5;
    }
        th.empty {
        background: transparent !important;
    }
    td:first-child {
        font-weight: bold;
    }
    tr.active {
        font-weight: bold;
        color: #000;
    }
    td {
        padding: 20px;
        font-size: 16px;
    }
    td:not(:first-child) {
        text-align: center;
    }
    th:not(.empty) {
        width: 150px
    }
}

.package .name {
    display: block;
    font-size: 16px;
}
.package .currency {
    display: inline;
}
.package .price {
    display: inline-block;
    font-size: 30px;
    margin: 0;
    line-height: 1;
}
.package .interval {
    display: inline-block;
    font-size: 10px;
    color: #7b7b7b;
}
