/*** --------------------------------------------------
Integrations styles
--------------------------------------------------- ***/

.integration-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 5% 15%;
    background-color: #f9f9f9;
    text-align: center;
    color: #000;
    box-shadow: 0 1px 2px 0 rgba(136,144,195,0.2), 0 2px 5px 0 rgba(37,44,97,0.15);
    .integration-service-logo {
        margin: 0 auto;
        background: #fff;
        padding: 35px;
        border-radius: 6px;
    }
    .plus {
        margin: 0 auto;
    }
    &>.plus>.material-icons {
        font-size: 60px;
    }
}

.integration-details {
    .integration-title {
        font-size: 34px;
    }
}

.integrations-header {
    margin-bottom: 40px;
}

.integration-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.integration-listing-item {
    display: flex;
    flex-direction: column;
    flex: 20%;
    margin: 10px;
    padding: 20px 0;
    font-size: 22px;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(136,144,195,0.2), 0 2px 5px 0 rgba(37,44,97,0.15);

    .integration-listing-item-link {
        &:hover {
            text-decoration: none;
        }
    }

    .integration-listing-item-header {
        margin: auto;
        font-size: 16px;
        color: $headings-color;
        font-weight: 600;
    }

    .integration-grid-img {
        max-height: 80px;
        display: block;
        margin: 20px auto;
    }

    .integration-listing-item-excerpt {
        position: relative;
        height: 2.8em;
        padding: 0 10px;
        margin-top: 5px;
        overflow: hidden;
        font-size: 13px;
        color: $text-color;
    }
}

.integration-details-title {
  border-bottom: 1px solid #ededed;
  padding-bottom:20px;
  margin-bottom: 20px;
}

.integration-details-img {
  height: 100px;
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 1200px) and (min-width: 768px) {
  .integration-listing-item {
    flex: 32%;
  }
}
/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .integration-listing-item {
       flex: 100%;
    }
    .integration-banner {
        padding: 5%;
        .integration-service-logo {
            padding: 15px;
        }
        .integration-details-img {
            height: 75px;
        }
        &>.plus>.material-icons {
            font-size: 40px;
        }
    }
}
